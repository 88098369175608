<template>
  <div class="about">
    <h1>Sobre esta pagina</h1>
    <Card>
      <template #content>
        <p>
          Esta página web muestra los repositorios de GitHub subidos por
          Patricio Aguilar Vargas. El fin de esto es mostrar los desarrollos de
          software
        </p>
        <p>
          Cada repositorio tiene el link de los codigos fuentes, en GitHub,
          ademas de el sitio de prueba
        </p>
      </template>
    </Card>
  </div>
</template>
